import React, { useState } from 'react';
import './Navbar.css';

function Navbar() {
    const [name, setName] = useState("");

    return (
        <div className={"topnav" + name} id="myTopnav">
            <a className="logo">$btc</a>
            <a className='buy'>Buy</a>
            <a className={'ele' + (name == " responsive" ? "" : " nodisplay")} id='blank'>blank</a>
            <a className='ele'>Dexscreener</a>
            <a className='ele'>Telegram</a>
            <a className='ele'>Twitter</a>
            <a className="icon" onClick={() => {
                if(name == " responsive")
                    setName("");
                else
                    setName(" responsive");
            }}>
                <i className="fa fa-bars"></i>
            </a>
        </div>
    );
}

export default Navbar;
