import './App.css';
import Navbar from './components/navbar/Navbar';
import React, { useRef } from 'react';

function App() {

  const elementRef = useRef(null);

  const scrollBuy = () => {
    
    const elementPosition = elementRef.current.getBoundingClientRect().top;
    
    window.scrollTo({
      top: elementPosition - 100,
      behavior: 'smooth'
    });

  };

  return (
    <div className="App">
      <Navbar></Navbar>
      <div class="main-page">
        <div class="container">
          <div class="textbox" id='title'>
            <div className='title-text'>We are $BTC</div>
            <div className='title-text'><text>B</text>reaking <text>T</text>he <text>C</text>ycle</div>
          </div>
          <div class="textbox">
            <div className='desc'>This is Break The Cycle: The leading narrative on Coinbase's new Layer 2. Join us in breaking the cycle today.</div>
            <div className='button' onClick={scrollBuy}>Break the Cycle</div>
          </div>
        </div>
      </div>
      <div className='main-page-second'>
        <iframe src="https://www.youtube.com/embed/a4F9u8Z84YI?si=vOKLEtmYNQOLR8dx&autoplay=1" frameBorder="0" className='base-image'></iframe>
      </div>
      <div className='page'>
        <div className='page-content'>
          <h1 ref={elementRef}>How to Buy</h1>
          <h2>You can purchase $BTC through our Uniswap below</h2>
          <iframe
            src="https://app.uniswap.org/#/swap?exactField=input&exactAmount=10&inputCurrency=0x6b175474e89094c44da98b954eedeac495271d0f"
            height="660px"
            width="70%"
          />
        </div>
      </div>
      <div className='footer'>
        <div className='footer-content'>
          <h2>Join us today in breaking the cycle. </h2>
          <div className='button' onClick={scrollBuy}>Break the Cycle</div>
          <h4>$BTC Break the Cycle is not affiliated with Coinbase.</h4>
        </div>
      </div>
    </div>
  );
}

export default App;
